import $ from "jquery";
import "../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js";
import * as AOS from "../../assets/libs/aos";
import Footer from "@/components/footer/Footer";
import Header from "@/components/header/header";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import Toolbar from "@/components/toolbar/toolbar";

import Swal from "sweetalert2";

export default {
  name: "FindYourGlass",
  data() {
    return {
      imageSource: require("@/assets/img/build-color/building-img-color1.png"),
      list_build: [
        {
          id: 1,
          name: this.$t("findYourGlass.lowBuilding"),
          img: require("@/assets/img/Home/i-build-sm.png"),
          width_icon: "40",
          type_glass: [],
          type_color: [],
          range_protection: [
            {
              id: 1,
              name: this.$t("findYourGlass.properLight"),
              value: 3,
            },
            {
              id: 2,
              name: this.$t("findYourGlass.properHeatProtection"),
              value: 4,
            },
          ],
          protection: [
            { id: 1, name: this.$t("findYourGlass.safety"), value: true },
            {
              id: 2,
              name: this.$t("findYourGlass.UVProtection"),
              value: false,
            },
            {
              id: 3,
              name: this.$t("findYourGlass.noiseReducer"),
              value: true,
            },
          ],
        },
        {
          id: 2,
          name: this.$t("findYourGlass.highBuilding"),
          img: require("@/assets/img/Home/i-build-lg.png"),
          width_icon: "30",
          type_glass: [],
          type_color: [],
          range_protection: [
            {
              id: 1,
              name: this.$t("findYourGlass.properLight"),
              value: 3,
            },
            {
              id: 2,
              name: this.$t("findYourGlass.properHeatProtection"),
              value: 4,
            },
          ],
          protection: [
            { id: 1, name: this.$t("findYourGlass.safety"), value: true },
            {
              id: 2,
              name: this.$t("findYourGlass.UVProtection"),
              value: false,
            },
            {
              id: 3,
              name: this.$t("findYourGlass.noiseReducer"),
              value: true,
            },
          ],
        },
      ],
      type_glass: [
        {
          id: 1,
          name: "อาคารขนาดเล็ก",
          list_glass: [
            { id: 1, name: this.$t("findYourGlass.sheet") },
            { id: 2, name: this.$t("findYourGlass.laminated") },
            { id: 3, name: this.$t("findYourGlass.insulated") },
            { id: 4, name: this.$t("findYourGlass.laminatedInsulated") },
          ],
        },
        {
          id: 2,
          name: "อาคารขนาดสูง",
          list_glass: [
            { id: 2, name: this.$t("findYourGlass.laminated") },
            { id: 3, name: this.$t("findYourGlass.insulated") },
            { id: 4, name: this.$t("findYourGlass.laminatedInsulated") },
          ],
        },
      ],
      show_glass: [
        { id: 1, name: this.$t("findYourGlass.sheet") },
        { id: 2, name: this.$t("findYourGlass.laminated") },
        { id: 3, name: this.$t("findYourGlass.insulated") },
        { id: 4, name: this.$t("findYourGlass.laminatedInsulated") },
      ],
      show_building: [
        {
          name: this.$t("findYourGlass.allBuilding"),
          img: require("@/assets/img/findyourglass/build/build_1.png"),
        },
        {
          name: this.$t("findYourGlass.lowBuilding"),
          img: require("@/assets/img/findyourglass/build/build_3.png"),
        },
        {
          name: this.$t("findYourGlass.highBuilding"),
          img: require("@/assets/img/findyourglass/build/build_2.png"),
        },
      ],
      type_color: [
        { id: 1, name: this.$t("findYourGlass.clear"), color: "white-normal" },
        { id: 2, name: this.$t("findYourGlass.green"), color: "green-lime" },
        { id: 3, name: this.$t("findYourGlass.darkGrey"), color: "orange-normal" },
        { id: 4, name: this.$t("findYourGlass.skyBlue"), color: "sky-normal" },
        { id: 5, name: this.$t("findYourGlass.grey"), color: "gray-dark" },
        { id: 6, name: this.$t("findYourGlass.bronzeGold"), color: "yellow-light" },
        { id: 7, name: this.$t("findYourGlass.other"), color: "black-normal" },
      ],
      range_protection: [
        {
          id: 1,
          name: this.$t("findYourGlass.properLight"),
          img: require("@/assets/img/Home/icon/icon-type-sun.png"),
          remark: "Proper Light > 30%",
        },
        {
          id: 2,
          name: this.$t("findYourGlass.properHeatProtection"),
          img: require("@/assets/img/Home/icon/icon-type-hot.png"),
          remark: "Proper Heat Protection > 45%",
        },
      ],
      protection: [
        {
          id: 1,
          name: this.$t("findYourGlass.safety"),
          img: require("@/assets/img/Home/icon/icon-type-protection.png"),
        },
        {
          id: 2,
          name: this.$t("findYourGlass.UVProtection"),
          img: require("@/assets/img/Home/icon/icon-type-uv.png"),
        },
        {
          id: 3,
          name: this.$t("findYourGlass.noiseReducer"),
          img: require("@/assets/img/Home/icon/icon-type-sound.png"),
        },
      ],
      list_protection: [
        {
          id: 1,
          name: this.$t("findYourGlass.safety"),
          img: require("@/assets/img/findyourglass/i_protection/i_1.png"),
        },
        {
          id: 2,
          name: this.$t("findYourGlass.UVProtection"),
          img: require("@/assets/img/findyourglass/i_protection/i_2.png"),
        },
        {
          id: 3,
          name: this.$t("findYourGlass.noiseReducer"),
          img: require("@/assets/img/findyourglass/i_protection/i_3.png"),
        },
      ],
      value_of_range_glass: [
        { id: 1, name_display: "<-20", name_show: "<-20%" },
        { id: 2, name_display: "21-30", name_show: "21-30%" },
        { id: 3, name_display: "31-40", name_show: "31-40%" },
        { id: 4, name_display: "41-50", name_show: "41-50%" },
        { id: 5, name_display: "51-60", name_show: "51-60%" },
        { id: 6, name_display: "61->", name_show: "61%->" },
      ],
      list_result_glass: [],
      result: [],
      toggleEnable: false,
      advance: false,
      baseFront: this.$baseFront
    };
  },
  methods: {
    clear_search() {
      this.list_build = [
        {
          id: 1,
          name: this.$t("home.findYourGlass.lowBuilding"),
          img: require("@/assets/img/Home/i-build-sm.png"),
          width_icon: "40",
          type_glass: [],
          type_color: [],
          range_protection: [
            { id: 1, name: this.$t("home.findYourGlass.properLight"), value: 3 },
            { id: 2, name: this.$t("home.findYourGlass.properHeatProtection"), value: 4 },
          ],
          protection: [
            { id: 1, name: this.$t("home.findYourGlass.safety"), value: true },
            { id: 2, name: this.$t("home.findYourGlass.UVProtection"), value: false },
            { id: 3, name: this.$t("home.findYourGlass.noiseReducer"), value: true },
          ],
        },
        {
          id: 2,
          name: this.$t("home.findYourGlass.highBuilding"),
          img: require("@/assets/img/Home/i-build-lg.png"),
          width_icon: "30",
          type_glass: [],
          type_color: [],
          range_protection: [
            { id: 1, name: this.$t("home.findYourGlass.properLight"), value: 3 },
            { id: 2, name: this.$t("home.findYourGlass.properHeatProtection"), value: 4 },
          ],
          protection: [
            { id: 1, name: this.$t("home.findYourGlass.safety"), value: true },
            { id: 2, name: this.$t("home.findYourGlass.UVProtection"), value: false },
            { id: 3, name: this.$t("home.findYourGlass.noiseReducer"), value: true },
          ],
        },
      ];
      this.advance = false;
      this.result = [];
    },
    gotoProduct(id) {
      this.$router.push(`product-content/${id}`);
    },
    gotoDetail(item) {
      this.$router.push({ path: "/compare", query: { current_1: item.id } });
    },
    openLink(url) {
      console.log(url);
      window.open(url, "_blank");
    },
    async fetchTypeProduct() {
      await this.$axios.get("/api/show/type_product").then((response) => {
        if (response.data.code == 200) {
          this.type_product = response.data.data;
        }
      });
    },
    async fetchProduct() {
      await this.$axios.get("/api/show/product").then((response) => {
        if (response.data.code == 200) {
          this.list_result_glass = response.data.data;
        }
      });
    },
    toggleButton(id) {
      if (id == 1) {
        document
          .getElementById("find-your-glass-open-2")
          .classList.remove("show");
      } else {
        document
          .getElementById("find-your-glass-open-1")
          .classList.remove("show");
      }
      this.$refs["FYGBtn" + id][0].focus();
    },
    CloseCollapse(id) {
      this.$refs["FYG" + id][0].focus();
    },
    search_glass(data) {
      console.log(data);
      if (data.id == 1) {
        this.result = [];
        let filter_type_glass = [];
        let filter_type_color = [];
        let filter_vlt = [];
        let filter_shgc = [];
        let protect_1 = data.protection[0].value ? 1 : 0;
        let protect_2 = data.protection[1].value ? 1 : 0;
        let protect_3 = data.protection[2].value ? 1 : 0;
        // ค้นหาประเภทกระจก
        if (data.type_glass.length > 0) {
          filter_type_glass = this.list_result_glass.filter((i) =>
            data.type_glass.includes(i.type_glass)
          );
        } else {
          filter_type_glass = this.list_result_glass;
        }
        // ค้นหาโทนสี
        if (data.type_color.length > 0) {
          filter_type_color = filter_type_glass.filter((i) => {
            if (JSON.parse(i.type_color).length == 1) {
              return data.type_color.includes(
                JSON.parse(i.type_color)[0].color
              );
            } else if (JSON.parse(i.type_color).length > 1) {
              return (
                data.type_color.includes(JSON.parse(i.type_color)[0].color) ||
                data.type_color.includes(JSON.parse(i.type_color)[1].color)
              );
            }
          });
        } else {
          filter_type_color = filter_type_glass;
        }
        if (this.advance) {
          filter_vlt = filter_type_color.filter((i) => {
            if (data.range_protection[0].value == 1) {
              return parseFloat(i.vlt) <= 20;
            } else if (data.range_protection[0].value == 2) {
              return parseFloat(i.vlt) >= 21 && parseFloat(i.vlt) <= 30;
            } else if (data.range_protection[0].value == 3) {
              return parseFloat(i.vlt) >= 31 && parseFloat(i.vlt) <= 40;
            } else if (data.range_protection[0].value == 4) {
              return parseFloat(i.vlt) >= 41 && parseFloat(i.vlt) <= 50;
            } else if (data.range_protection[0].value == 5) {
              return parseFloat(i.vlt) >= 51 && parseFloat(i.vlt) <= 60;
            } else if (data.range_protection[0].value == 6) {
              return parseFloat(i.vlt) >= 61;
            }
          });

          // ค้นหาระดับการป้องกันความร้อน
          filter_shgc = filter_vlt.filter((i) => {
            if (data.range_protection[1].value == 1) {
              return parseFloat(i.shgc) <= 20;
            } else if (data.range_protection[1].value == 2) {
              return parseFloat(i.shgc) >= 21 && parseFloat(i.shgc) <= 30;
            } else if (data.range_protection[1].value == 3) {
              return parseFloat(i.shgc) >= 31 && parseFloat(i.shgc) <= 40;
            } else if (data.range_protection[1].value == 4) {
              return parseFloat(i.shgc) >= 41 && parseFloat(i.shgc) <= 50;
            } else if (data.range_protection[1].value == 5) {
              return parseFloat(i.shgc) >= 51 && parseFloat(i.shgc) <= 60;
            } else if (data.range_protection[1].value == 6) {
              return parseFloat(i.shgc) >= 61;
            }
          });

          // ค้นหาความปลอดภัย
          this.result = filter_shgc.filter(
            (i) =>
              i.protect_1 == protect_1 &&
              i.protect_2 == protect_2 &&
              i.protect_3 == protect_3 &&
              (i.type_build == 2 || i.type_build == 1)
          );
        } else {
          this.result = filter_type_color;
        }
        //ค้นหาระดับแสงส่องผ่านกระจก

        if (this.result.length == 0) {
          Swal.fire({
            icon: "error",
            title: this.$t("search.title"),
            text: this.$t("search.notFoundGlass"),
          });
        }
      } else if (data.id == 2) {
        this.result = [];
        let filter_type_glass = [];
        let filter_type_color = [];
        let filter_vlt = [];
        let filter_shgc = [];
        let protect_1 = data.protection[0].value ? 1 : 0;
        let protect_2 = data.protection[1].value ? 1 : 0;
        let protect_3 = data.protection[2].value ? 1 : 0;
        // ค้นหาประเภทกระจก
        if (data.type_glass.length > 0) {
          filter_type_glass = this.list_result_glass.filter((i) =>
            data.type_glass.includes(i.type_glass)
          );
        } else {
          filter_type_glass = this.list_result_glass;
        }
        // ค้นหาโทนสี
        if (data.type_color.length > 0) {
          filter_type_color = filter_type_glass.filter((i) => {
            if (JSON.parse(i.type_color).length == 1) {
              return data.type_color.includes(
                JSON.parse(i.type_color)[0].color
              );
            } else if (JSON.parse(i.type_color).length > 1) {
              return (
                data.type_color.includes(JSON.parse(i.type_color)[0].color) ||
                data.type_color.includes(JSON.parse(i.type_color)[1].color)
              );
            }
          });
        } else {
          filter_type_color = filter_type_glass;
        }
        //ค้นหาระดับแสงส่องผ่านกระจก
        if (this.advance) {
          filter_vlt = filter_type_color.filter((i) => {
            if (data.range_protection[0].value == 1) {
              return parseFloat(i.vlt) <= 20;
            } else if (data.range_protection[0].value == 2) {
              return parseFloat(i.vlt) >= 21 && parseFloat(i.vlt) <= 30;
            } else if (data.range_protection[0].value == 3) {
              return parseFloat(i.vlt) >= 31 && parseFloat(i.vlt) <= 40;
            } else if (data.range_protection[0].value == 4) {
              return parseFloat(i.vlt) >= 41 && parseFloat(i.vlt) <= 50;
            } else if (data.range_protection[0].value == 5) {
              return parseFloat(i.vlt) >= 51 && parseFloat(i.vlt) <= 60;
            } else if (data.range_protection[0].value == 6) {
              return parseFloat(i.vlt) >= 61;
            }
          });

          // ค้นหาระดับการป้องกันความร้อน
          filter_shgc = filter_vlt.filter((i) => {
            if (data.range_protection[1].value == 1) {
              return parseFloat(i.shgc) <= 20;
            } else if (data.range_protection[1].value == 2) {
              return parseFloat(i.shgc) >= 21 && parseFloat(i.shgc) <= 30;
            } else if (data.range_protection[1].value == 3) {
              return parseFloat(i.shgc) >= 31 && parseFloat(i.shgc) <= 40;
            } else if (data.range_protection[1].value == 4) {
              return parseFloat(i.shgc) >= 41 && parseFloat(i.shgc) <= 50;
            } else if (data.range_protection[1].value == 5) {
              return parseFloat(i.shgc) >= 51 && parseFloat(i.shgc) <= 60;
            } else if (data.range_protection[1].value == 6) {
              return parseFloat(i.shgc) >= 61;
            }
          });

          // ค้นหาความปลอดภัย
          this.result = filter_shgc.filter(
            (i) =>
              i.protect_1 == protect_1 &&
              i.protect_2 == protect_2 &&
              i.protect_3 == protect_3 &&
              (i.type_build == 3 || i.type_build == 1)
          );
          console.log(this.result);
        } else {
          this.result = filter_type_color;
        }

        if (this.result.length == 0) {
          Swal.fire({
            icon: "error",
            title: this.$t("search.title"),
            text: "ไม่พบข้อมูลกระจกที่คุณค้นหา",
          });
        }
      }
      console.log(data);
    },
    search_glass_all(data) {
      if (data.id == 1) {
        this.result = [];
        // ค้นหาความปลอดภัย
        this.result = this.list_result_glass.filter(
          (i) => i.type_build == 2 || i.type_build == 1
        );
      } else if (data.id == 2) {
        this.result = [];
        // ค้นหาความปลอดภัย
        this.result = this.list_result_glass.filter(
          (i) => i.type_build == 3 || i.type_build == 1
        );
      }
      console.log(this.result);
    },
  },
  async mounted() {
    console.log(this.list_build);
    window.scrollTo(0, 0);
    await this.fetchTypeProduct();
    await this.fetchProduct();
    document.title = "AGC - Home";
    AOS.init();
    resize();
    function resize() {
      if (window.innerWidth < 800)
        $(".footer").css({ position: "relative", top: "0px" });
    }
    // eslint-disable-next-line
    $(".m-search-BT").on("click", (evt) => {
      $(".m-search-BT + .dp").toggle();
    });
    $('img[alt="color"]').on("click", (evt) => {
      $('img[alt="color"].active').removeClass("active");
      $("#" + evt.target.id).addClass("active");
      // $('img[alt="building-img"]').attr('src',`/img/build-color/building-img-${evt.target.id}.png`);
      this.imageSource = require(`@/assets/img/build-color/building-img-${evt.target.id}.png`);
    });

    if (this.$route.query.findyourglass) {
      console.log('.................');
      let findyourglass = this.$route.query.findyourglass;
      this.advance = JSON.parse(this.$route.query.advance)
      const section = document.getElementById("search-glass");
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth"
      });
      if (findyourglass.id == 1) {
        this.list_build[0].protection = findyourglass.protection;
        this.list_build[0].type_color = findyourglass.type_color;
        this.list_build[0].type_glass = findyourglass.type_glass;
        this.list_build[0].range_protection = findyourglass.range_protection;
        this.search_glass(this.list_build[0]);
      } else {
        this.list_build[1].protection = findyourglass.protection;
        this.list_build[1].type_color = findyourglass.type_color;
        this.list_build[1].type_glass = findyourglass.type_glass;
        this.list_build[1].range_protection = findyourglass.range_protection;
        this.search_glass(this.list_build[1]);
      }
    }
  },
  components: {
    Footer,
    Header,
    Breadcrumb,
    Toolbar,
  },
};
